import * as i0 from '@angular/core';
import { Injectable, Optional, EventEmitter, Directive, Output, Input, HostListener, NgModule } from '@angular/core';
var PADDLE_EVENT_TYPE;
(function (PADDLE_EVENT_TYPE) {
  PADDLE_EVENT_TYPE["CheckoutLoaded"] = "Checkout.Loaded";
  PADDLE_EVENT_TYPE["CheckoutClose"] = "Checkout.Close";
  PADDLE_EVENT_TYPE["CheckoutComplete"] = "Checkout.Complete";
  PADDLE_EVENT_TYPE["CheckoutUserSubscribed"] = "Checkout.User.Subscribed";
  PADDLE_EVENT_TYPE["CheckoutQuantityChange"] = "Checkout.Quantity.Change";
  PADDLE_EVENT_TYPE["CheckoutLogin"] = "Checkout.Login";
  PADDLE_EVENT_TYPE["CheckoutLogout"] = "Checkout.Logout";
  PADDLE_EVENT_TYPE["CheckoutCountryChanged"] = "Checkout.CountryChanged";
  PADDLE_EVENT_TYPE["CheckoutPaymentMethodSelected"] = "Checkout.PaymentMethodSelected";
  PADDLE_EVENT_TYPE["CheckoutCouponAdd"] = "Checkout.Coupon.Add";
  PADDLE_EVENT_TYPE["CheckoutCouponSubmit"] = "Checkout.Coupon.Submit";
  PADDLE_EVENT_TYPE["CheckoutCouponCancel"] = "Checkout.Coupon.Cancel";
  PADDLE_EVENT_TYPE["CheckoutCouponApplied"] = "Checkout.Coupon.Applied";
  PADDLE_EVENT_TYPE["CheckoutCouponRemove"] = "Checkout.Coupon.Remove";
  PADDLE_EVENT_TYPE["CheckoutError"] = "Checkout.Error";
  PADDLE_EVENT_TYPE["CheckoutLocationSubmit"] = "Checkout.Location.Submit";
  PADDLE_EVENT_TYPE["CheckoutApplePay"] = "Checkout.ApplePay";
  PADDLE_EVENT_TYPE["CheckoutLanguageChange"] = "Checkout.Language.Change";
  PADDLE_EVENT_TYPE["CheckoutVatAdd"] = "Checkout.Vat.Add";
  PADDLE_EVENT_TYPE["CheckoutVatCancel"] = "Checkout.Vat.Cancel";
  PADDLE_EVENT_TYPE["CheckoutVatSubmit"] = "Checkout.Vat.Submit";
  PADDLE_EVENT_TYPE["CheckoutVatApplied"] = "Checkout.Vat.Applied";
  PADDLE_EVENT_TYPE["CheckoutVatRemove"] = "Checkout.Vat.Remove";
  PADDLE_EVENT_TYPE["CheckoutPaymentMethodsShowMore"] = "Checkout.PaymentMethods.ShowMore";
  PADDLE_EVENT_TYPE["CheckoutPaymentMethodsShowDefaults"] = "Checkout.PaymentMethods.ShowDefaults";
  PADDLE_EVENT_TYPE["CheckoutDuplicateWarningShown"] = "Checkout.DuplicateWarningShown";
  PADDLE_EVENT_TYPE["CheckoutWireTransferComplete"] = "Checkout.WireTransfer.Complete";
  PADDLE_EVENT_TYPE["CheckoutPaymentComplete"] = "Checkout.PaymentComplete";
})(PADDLE_EVENT_TYPE || (PADDLE_EVENT_TYPE = {}));
var PADDLE_LOCALE;
(function (PADDLE_LOCALE) {
  PADDLE_LOCALE["ar"] = "ar";
  PADDLE_LOCALE["da"] = "da";
  PADDLE_LOCALE["de"] = "de";
  PADDLE_LOCALE["en"] = "en";
  PADDLE_LOCALE["es"] = "es";
  PADDLE_LOCALE["fr"] = "fr";
  PADDLE_LOCALE["it"] = "it";
  PADDLE_LOCALE["ja"] = "ja";
  PADDLE_LOCALE["ko"] = "ko";
  PADDLE_LOCALE["nl"] = "nl";
  PADDLE_LOCALE["no"] = "no";
  PADDLE_LOCALE["pl"] = "pl";
  PADDLE_LOCALE["pt"] = "pt";
  PADDLE_LOCALE["ru"] = "ru";
  PADDLE_LOCALE["sv"] = "sv";
  PADDLE_LOCALE["zh"] = "Zh-Hans";
})(PADDLE_LOCALE || (PADDLE_LOCALE = {}));
var PADDLE_COUNTRY;
(function (PADDLE_COUNTRY) {
  PADDLE_COUNTRY["AD"] = "AD";
  PADDLE_COUNTRY["AE"] = "AE";
  PADDLE_COUNTRY["AF"] = "AF";
  PADDLE_COUNTRY["AG"] = "AG";
  PADDLE_COUNTRY["AI"] = "AI";
  PADDLE_COUNTRY["AL"] = "AL";
  PADDLE_COUNTRY["AM"] = "AM";
  PADDLE_COUNTRY["AN"] = "AN";
  PADDLE_COUNTRY["AO"] = "AO";
  PADDLE_COUNTRY["AR"] = "AR";
  PADDLE_COUNTRY["AS"] = "AS";
  PADDLE_COUNTRY["AT"] = "AT";
  PADDLE_COUNTRY["AU"] = "AU";
  PADDLE_COUNTRY["AW"] = "AW";
  PADDLE_COUNTRY["AZ"] = "AZ";
  PADDLE_COUNTRY["BA"] = "BA";
  PADDLE_COUNTRY["BB"] = "BB";
  PADDLE_COUNTRY["BD"] = "BD";
  PADDLE_COUNTRY["BE"] = "BE";
  PADDLE_COUNTRY["BF"] = "BF";
  PADDLE_COUNTRY["BG"] = "BG";
  PADDLE_COUNTRY["BH"] = "BH";
  PADDLE_COUNTRY["BI"] = "BI";
  PADDLE_COUNTRY["BJ"] = "BJ";
  PADDLE_COUNTRY["BM"] = "BM";
  PADDLE_COUNTRY["BN"] = "BN";
  PADDLE_COUNTRY["BO"] = "BO";
  PADDLE_COUNTRY["BR"] = "BR";
  PADDLE_COUNTRY["BS"] = "BS";
  PADDLE_COUNTRY["BT"] = "BT";
  PADDLE_COUNTRY["BV"] = "BV";
  PADDLE_COUNTRY["BW"] = "BW";
  PADDLE_COUNTRY["BY"] = "BY";
  PADDLE_COUNTRY["BZ"] = "BZ";
  PADDLE_COUNTRY["CA"] = "CA";
  PADDLE_COUNTRY["CC"] = "CC";
  PADDLE_COUNTRY["CF"] = "CF";
  PADDLE_COUNTRY["CG"] = "CG";
  PADDLE_COUNTRY["CH"] = "CH";
  PADDLE_COUNTRY["CI"] = "CI";
  PADDLE_COUNTRY["CK"] = "CK";
  PADDLE_COUNTRY["CL"] = "CL";
  PADDLE_COUNTRY["CM"] = "CM";
  PADDLE_COUNTRY["CN"] = "CN";
  PADDLE_COUNTRY["CO"] = "CO";
  PADDLE_COUNTRY["CR"] = "CR";
  PADDLE_COUNTRY["CU"] = "CU";
  PADDLE_COUNTRY["CV"] = "CV";
  PADDLE_COUNTRY["CW"] = "CW";
  PADDLE_COUNTRY["CX"] = "CX";
  PADDLE_COUNTRY["CY"] = "CY";
  PADDLE_COUNTRY["CZ"] = "CZ";
  PADDLE_COUNTRY["DE"] = "DE";
  PADDLE_COUNTRY["DJ"] = "DJ";
  PADDLE_COUNTRY["DK"] = "DK";
  PADDLE_COUNTRY["DM"] = "DM";
  PADDLE_COUNTRY["DO"] = "DO";
  PADDLE_COUNTRY["DZ"] = "DZ";
  PADDLE_COUNTRY["EC"] = "EC";
  PADDLE_COUNTRY["EE"] = "EE";
  PADDLE_COUNTRY["EG"] = "EG";
  PADDLE_COUNTRY["EH"] = "EH";
  PADDLE_COUNTRY["ER"] = "ER";
  PADDLE_COUNTRY["ES"] = "ES";
  PADDLE_COUNTRY["ET"] = "ET";
  PADDLE_COUNTRY["FI"] = "FI";
  PADDLE_COUNTRY["FJ"] = "FJ";
  PADDLE_COUNTRY["FK"] = "FK";
  PADDLE_COUNTRY["FM"] = "FM";
  PADDLE_COUNTRY["FO"] = "FO";
  PADDLE_COUNTRY["FR"] = "FR";
  PADDLE_COUNTRY["GA"] = "GA";
  PADDLE_COUNTRY["GB"] = "GB";
  PADDLE_COUNTRY["GD"] = "GD";
  PADDLE_COUNTRY["GE"] = "GE";
  PADDLE_COUNTRY["GF"] = "GF";
  PADDLE_COUNTRY["GG"] = "GG";
  PADDLE_COUNTRY["GH"] = "GH";
  PADDLE_COUNTRY["GI"] = "GI";
  PADDLE_COUNTRY["GL"] = "GL";
  PADDLE_COUNTRY["GM"] = "GM";
  PADDLE_COUNTRY["GN"] = "GN";
  PADDLE_COUNTRY["GP"] = "GP";
  PADDLE_COUNTRY["GQ"] = "GQ";
  PADDLE_COUNTRY["GR"] = "GR";
  PADDLE_COUNTRY["GS"] = "GS";
  PADDLE_COUNTRY["GT"] = "GT";
  PADDLE_COUNTRY["GU"] = "GU";
  PADDLE_COUNTRY["GW"] = "GW";
  PADDLE_COUNTRY["GY"] = "GY";
  PADDLE_COUNTRY["HK"] = "HK";
  PADDLE_COUNTRY["HM"] = "HM";
  PADDLE_COUNTRY["HN"] = "HN";
  PADDLE_COUNTRY["HR"] = "HR";
  PADDLE_COUNTRY["HT"] = "HT";
  PADDLE_COUNTRY["HU"] = "HU";
  PADDLE_COUNTRY["ID"] = "ID";
  PADDLE_COUNTRY["IE"] = "IE";
  PADDLE_COUNTRY["IL"] = "IL";
  PADDLE_COUNTRY["IN"] = "IN";
  PADDLE_COUNTRY["IO"] = "IO";
  PADDLE_COUNTRY["IQ"] = "IQ";
  PADDLE_COUNTRY["IR"] = "IR";
  PADDLE_COUNTRY["IS"] = "IS";
  PADDLE_COUNTRY["IT"] = "IT";
  PADDLE_COUNTRY["JE"] = "JE";
  PADDLE_COUNTRY["JM"] = "JM";
  PADDLE_COUNTRY["JO"] = "JO";
  PADDLE_COUNTRY["JP"] = "JP";
  PADDLE_COUNTRY["KE"] = "KE";
  PADDLE_COUNTRY["KG"] = "KG";
  PADDLE_COUNTRY["KH"] = "KH";
  PADDLE_COUNTRY["KI"] = "KI";
  PADDLE_COUNTRY["KM"] = "KM";
  PADDLE_COUNTRY["KN"] = "KN";
  PADDLE_COUNTRY["KP"] = "KP";
  PADDLE_COUNTRY["KR"] = "KR";
  PADDLE_COUNTRY["KW"] = "KW";
  PADDLE_COUNTRY["KY"] = "KY";
  PADDLE_COUNTRY["KZ"] = "KZ";
  PADDLE_COUNTRY["LA"] = "LA";
  PADDLE_COUNTRY["LB"] = "LB";
  PADDLE_COUNTRY["LC"] = "LC";
  PADDLE_COUNTRY["LI"] = "LI";
  PADDLE_COUNTRY["LK"] = "LK";
  PADDLE_COUNTRY["LR"] = "LR";
  PADDLE_COUNTRY["LS"] = "LS";
  PADDLE_COUNTRY["LT"] = "LT";
  PADDLE_COUNTRY["LU"] = "LU";
  PADDLE_COUNTRY["LV"] = "LV";
  PADDLE_COUNTRY["LY"] = "LY";
  PADDLE_COUNTRY["MA"] = "MA";
  PADDLE_COUNTRY["MC"] = "MC";
  PADDLE_COUNTRY["MD"] = "MD";
  PADDLE_COUNTRY["ME"] = "ME";
  PADDLE_COUNTRY["MG"] = "MG";
  PADDLE_COUNTRY["MH"] = "MH";
  PADDLE_COUNTRY["MK"] = "MK";
  PADDLE_COUNTRY["ML"] = "ML";
  PADDLE_COUNTRY["MM"] = "MM";
  PADDLE_COUNTRY["MN"] = "MN";
  PADDLE_COUNTRY["MO"] = "MO";
  PADDLE_COUNTRY["MP"] = "MP";
  PADDLE_COUNTRY["MQ"] = "MQ";
  PADDLE_COUNTRY["MR"] = "MR";
  PADDLE_COUNTRY["MS"] = "MS";
  PADDLE_COUNTRY["MT"] = "MT";
  PADDLE_COUNTRY["MU"] = "MU";
  PADDLE_COUNTRY["MV"] = "MV";
  PADDLE_COUNTRY["MW"] = "MW";
  PADDLE_COUNTRY["MX"] = "MX";
  PADDLE_COUNTRY["MY"] = "MY";
  PADDLE_COUNTRY["MZ"] = "MZ";
  PADDLE_COUNTRY["NA"] = "NA";
  PADDLE_COUNTRY["NC"] = "NC";
  PADDLE_COUNTRY["NE"] = "NE";
  PADDLE_COUNTRY["NF"] = "NF";
  PADDLE_COUNTRY["NG"] = "NG";
  PADDLE_COUNTRY["NI"] = "NI";
  PADDLE_COUNTRY["NL"] = "NL";
  PADDLE_COUNTRY["NO"] = "NO";
  PADDLE_COUNTRY["NP"] = "NP";
  PADDLE_COUNTRY["NR"] = "NR";
  PADDLE_COUNTRY["NU"] = "NU";
  PADDLE_COUNTRY["NZ"] = "NZ";
  PADDLE_COUNTRY["OM"] = "OM";
  PADDLE_COUNTRY["PA"] = "PA";
  PADDLE_COUNTRY["PE"] = "PE";
  PADDLE_COUNTRY["PF"] = "PF";
  PADDLE_COUNTRY["PG"] = "PG";
  PADDLE_COUNTRY["PH"] = "PH";
  PADDLE_COUNTRY["PK"] = "PK";
  PADDLE_COUNTRY["PL"] = "PL";
  PADDLE_COUNTRY["PM"] = "PM";
  PADDLE_COUNTRY["PN"] = "PN";
  PADDLE_COUNTRY["PR"] = "PR";
  PADDLE_COUNTRY["PS"] = "PS";
  PADDLE_COUNTRY["PT"] = "PT";
  PADDLE_COUNTRY["PW"] = "PW";
  PADDLE_COUNTRY["PY"] = "PY";
  PADDLE_COUNTRY["QA"] = "QA";
  PADDLE_COUNTRY["RE"] = "RE";
  PADDLE_COUNTRY["RO"] = "RO";
  PADDLE_COUNTRY["RS"] = "RS";
  PADDLE_COUNTRY["RU"] = "RU";
  PADDLE_COUNTRY["RW"] = "RW";
  PADDLE_COUNTRY["SA"] = "SA";
  PADDLE_COUNTRY["SB"] = "SB";
  PADDLE_COUNTRY["SC"] = "SC";
  PADDLE_COUNTRY["SD"] = "SD";
  PADDLE_COUNTRY["SE"] = "SE";
  PADDLE_COUNTRY["SG"] = "SG";
  PADDLE_COUNTRY["SH"] = "SH";
  PADDLE_COUNTRY["SI"] = "SI";
  PADDLE_COUNTRY["SJ"] = "SJ";
  PADDLE_COUNTRY["SK"] = "SK";
  PADDLE_COUNTRY["SL"] = "SL";
  PADDLE_COUNTRY["SM"] = "SM";
  PADDLE_COUNTRY["SN"] = "SN";
  PADDLE_COUNTRY["SO"] = "SO";
  PADDLE_COUNTRY["SR"] = "SR";
  PADDLE_COUNTRY["ST"] = "ST";
  PADDLE_COUNTRY["SV"] = "SV";
  PADDLE_COUNTRY["SY"] = "SY";
  PADDLE_COUNTRY["SZ"] = "SZ";
  PADDLE_COUNTRY["TC"] = "TC";
  PADDLE_COUNTRY["TD"] = "TD";
  PADDLE_COUNTRY["TF"] = "TF";
  PADDLE_COUNTRY["TG"] = "TG";
  PADDLE_COUNTRY["TH"] = "TH";
  PADDLE_COUNTRY["TJ"] = "TJ";
  PADDLE_COUNTRY["TK"] = "TK";
  PADDLE_COUNTRY["TL"] = "TL";
  PADDLE_COUNTRY["TM"] = "TM";
  PADDLE_COUNTRY["TN"] = "TN";
  PADDLE_COUNTRY["TO"] = "TO";
  PADDLE_COUNTRY["TR"] = "TR";
  PADDLE_COUNTRY["TT"] = "TT";
  PADDLE_COUNTRY["TV"] = "TV";
  PADDLE_COUNTRY["TW"] = "TW";
  PADDLE_COUNTRY["TZ"] = "TZ";
  PADDLE_COUNTRY["UA"] = "UA";
  PADDLE_COUNTRY["UG"] = "UG";
  PADDLE_COUNTRY["UM"] = "UM";
  PADDLE_COUNTRY["US"] = "US";
  PADDLE_COUNTRY["UY"] = "UY";
  PADDLE_COUNTRY["UZ"] = "UZ";
  PADDLE_COUNTRY["VA"] = "VA";
  PADDLE_COUNTRY["VC"] = "VC";
  PADDLE_COUNTRY["VE"] = "VE";
  PADDLE_COUNTRY["VG"] = "VG";
  PADDLE_COUNTRY["VI"] = "VI";
  PADDLE_COUNTRY["VN"] = "VN";
  PADDLE_COUNTRY["VU"] = "VU";
  PADDLE_COUNTRY["WF"] = "WF";
  PADDLE_COUNTRY["WS"] = "WS";
  PADDLE_COUNTRY["YE"] = "YE";
  PADDLE_COUNTRY["YT"] = "YT";
  PADDLE_COUNTRY["ZA"] = "ZA";
  PADDLE_COUNTRY["ZM"] = "ZM";
  PADDLE_COUNTRY["ZW"] = "ZW";
})(PADDLE_COUNTRY || (PADDLE_COUNTRY = {}));
class PaddleServiceConfig {
  constructor() {
    this.sandbox = false;
  }
}
class PaddleService {
  constructor(config) {
    this.sandbox = false;
    if (config) {
      this.sandbox = config.sandbox;
    }
  }
  /**
   * Create a Paddle instance as soon as Paddle has loaded.
   * @param PaddleConfig config
   * @returns Promise<PaddleHandler>
   */
  create(config) {
    return this.load().then(() => {
      if (this.sandbox) {
        Paddle.Environment.set('sandbox');
      }
      Paddle.Setup(config);
    });
  }
  open(checkout) {
    if (this.loaded) {
      Paddle.Checkout.open(checkout);
    }
  }
  getPrice(productId, quantity = 1) {
    if (this.loaded) {
      return new Promise((resolve, reject) => {
        Paddle.Product.Prices(productId, quantity, prices => {
          if (!prices) reject('Error getting prices');
          resolve(prices);
        });
      });
    }
    return Promise.reject('Paddle not loaded');
  }
  /**
   * Load or wait for the Paddle library to load.
   * @returns Promise<void>
   */
  load() {
    if (!this.loaded) {
      this.loaded = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://cdn.paddle.com/paddle/paddle.js';
        script.onerror = e => reject(e);
        if (script.readyState) {
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              resolve();
            }
          };
        } else {
          script.onload = () => {
            resolve();
          };
        }
        document.getElementsByTagName('body')[0].appendChild(script);
      });
    }
    return this.loaded;
  }
  static {
    this.ɵfac = function PaddleService_Factory(t) {
      return new (t || PaddleService)(i0.ɵɵinject(PaddleServiceConfig, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PaddleService,
      factory: PaddleService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PaddleService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: PaddleServiceConfig,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
class PaddleDirective {
  constructor(paddleServ) {
    this.paddleServ = paddleServ;
    this.onCheckoutEvent = new EventEmitter();
  }
  async ngOnInit() {
    await this.paddleServ.create({
      vendor: this.vendor,
      eventCallback: data => {
        this.onCheckoutEvent.emit(data);
      }
    });
  }
  onClick($event) {
    this.paddleServ.open({
      product: this.product,
      title: this.title,
      message: this.message,
      coupon: this.coupon,
      email: this.email
    });
  }
  static {
    this.ɵfac = function PaddleDirective_Factory(t) {
      return new (t || PaddleDirective)(i0.ɵɵdirectiveInject(PaddleService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PaddleDirective,
      selectors: [["", "ngxPaddle", ""]],
      hostBindings: function PaddleDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function PaddleDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event);
          });
        }
      },
      inputs: {
        vendor: "vendor",
        product: "product",
        title: "title",
        message: "message",
        coupon: "coupon",
        email: "email"
      },
      outputs: {
        onCheckoutEvent: "onCheckoutEvent"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PaddleDirective, [{
    type: Directive,
    args: [{
      selector: '[ngxPaddle]'
    }]
  }], () => [{
    type: PaddleService
  }], {
    onCheckoutEvent: [{
      type: Output
    }],
    vendor: [{
      type: Input
    }],
    product: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    message: [{
      type: Input
    }],
    coupon: [{
      type: Input
    }],
    email: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
class PaddleModule {
  static forRoot(config) {
    return {
      ngModule: PaddleModule,
      providers: [{
        provide: PaddleServiceConfig,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function PaddleModule_Factory(t) {
      return new (t || PaddleModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PaddleModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [PaddleService]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PaddleModule, [{
    type: NgModule,
    args: [{
      declarations: [PaddleDirective],
      imports: [],
      providers: [PaddleService],
      exports: [PaddleDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of paddle
 */

/**
 * Generated bundle index. Do not edit.
 */

export { PADDLE_COUNTRY, PADDLE_EVENT_TYPE, PADDLE_LOCALE, PaddleDirective, PaddleModule, PaddleService, PaddleServiceConfig };
